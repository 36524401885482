import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { FaHome, FaUserShield, FaBook, FaUserGraduate, FaChalkboardTeacher, FaRegBell, FaClipboardList, FaEnvelope, FaCreditCard } from 'react-icons/fa';
import styles from './css/Navbar.module.css';

const CustomNavbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef(null); // Reference for the navbar

  const handleToggle = () => setIsExpanded(!isExpanded);
  const handleLinkClick = () => setIsExpanded(false);

  // Close the navbar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Common style for links
  const linkStyle = {
    color: 'white',
    padding: '12px 20px',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition for background, transform, and shadow
    borderRadius: '5px', // Slight rounding for links
  };

  // Hover effect style for links
  const hoverStyle = {
    backgroundColor: '#a4d65e', // Light green background on hover
    transform: 'scale(1.05)', // Slightly scale up on hover
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // More pronounced shadow effect on hover
  };

  return (
    <Navbar className={styles.customNavbar} expand="lg" fixed="top" ref={navbarRef} expanded={isExpanded}>
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/" className={styles.navbarBrand}>
          <img src={'https://amreshbhuya.github.io/www.amresh.com/Sambalpur_University_logo.png'} alt="Library Logo" className={styles.navbarLogo} />
          SUIIT Library
        </Navbar.Brand>
        <Navbar.Toggle onClick={handleToggle} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`me-auto ${styles.navbarLinks}`}>
            <Nav.Link as={Link} to="/" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaHome className={styles.navbarIcon} /> Home
            </Nav.Link>
            <Nav.Link as={Link} to="/admin" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaUserShield className={styles.navbarIcon} /> Admin Panel
            </Nav.Link>
            <Nav.Link as={Link} to="/books" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaBook className={styles.navbarIcon} /> View Books
            </Nav.Link>
            <Nav.Link as={Link} to="/student" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaUserGraduate className={styles.navbarIcon} /> Student
            </Nav.Link>
            <Nav.Link as={Link} to="/faculty" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaChalkboardTeacher className={styles.navbarIcon} /> Faculty
            </Nav.Link>
            <Nav.Link as={Link} to="/notices" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaRegBell className={styles.navbarIcon} /> Notices
            </Nav.Link>
            <Nav.Link as={Link} to="/required-books" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaClipboardList className={styles.navbarIcon} /> Required Books
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaEnvelope className={styles.navbarIcon} /> Contact
            </Nav.Link>
            <Nav.Link as={Link} to="/payment" onClick={handleLinkClick} style={linkStyle} onMouseOver={e => e.currentTarget.style = { ...linkStyle, ...hoverStyle }} onMouseOut={e => e.currentTarget.style = linkStyle}>
              <FaCreditCard className={styles.navbarIcon} /> Payments
            </Nav.Link>
            

          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default CustomNavbar;
