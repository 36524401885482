import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './pages/Navbar';
import { auth, database } from './firebaseConfig'; // Firebase config
import { ref, onValue } from 'firebase/database'; // Firebase database methods
import { saveDataToDB, getDataFromDB } from './indexedDB'; // IndexedDB utility
import './App.css'; // Ensure your CSS file is imported

// Lazy loading components
const AdminPage = lazy(() => import('./pages/AdminPage'));
const StudentPage = lazy(() => import('./pages/StudentPage'));
const FacultyPage = lazy(() => import('./pages/FacultyPage'));
const NoticePage = lazy(() => import('./pages/NoticePage'));
const RequiredBookPage = lazy(() => import('./pages/RequiredBookPage'));
const Contact = lazy(() => import('./pages/contact'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const AddBook = lazy(() => import('./components/AddBook'));
const Scanner = lazy(() => import('./components/scanner'));
const BookListPage = lazy(() => import('./pages/BookListPage'));
const IssueBook = lazy(() => import('./components/IssueBook'));
const MyBooks = lazy(() => import('./pages/MyBooks'));
const HomePage = lazy(() => import('./pages/HomePage'));
const AddNotice = lazy(() => import('./pages/AddNotice'));
const UserRequestedBooksPage = lazy(() => import('./pages/UserRequestedBooksPage'));
const UserList = lazy(() => import('./pages/UserList'));
const UserChat = lazy(() => import('./pages/UserChat'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsandConditions = lazy(() => import('./pages/TermsandConditions'));
const CancellationandRefund = lazy(() => import('./pages/CancellationandRefund'));
const ShippingandDelivery = lazy(() => import('./pages/ShippingandDelivery'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const AdminPaymentPage = lazy(() => import('./pages/AdminPaymentPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const Gallery = lazy(() => import('./components/Gallery'));
const AddToGallery = lazy(() => import('./components/AddToGallery'));



const App = () => {
  const [user, setUser] = useState(null);
  const [books, setBooks] = useState([]);
  const [issuedBooks, setIssuedBooks] = useState([]);

  useEffect(() => {
    // Monitor auth state change
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Check if the user is an admin
  const isAdmin = user && (user.email === "22btcse06@suiit.ac.in" || user.email === "library@suiit.ac.in");

  // Function to fetch books from Firebase or IndexedDB
  const fetchBooks = async () => {
    try {
      const booksRef = ref(database, 'books/');
      onValue(booksRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const bookArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setBooks(bookArray);
          saveDataToDB('books', bookArray); // Save to IndexedDB
          localStorage.setItem('books', JSON.stringify(bookArray)); // Save to local storage
        }
      });
    } catch (error) {
      console.log('Offline: Fetching books from IndexedDB');
      const offlineBooks = await getDataFromDB('books');
      setBooks(offlineBooks);
    }
  };

  // Function to fetch issued books from Firebase or IndexedDB
  const fetchIssuedBooks = async () => {
    try {
      const issuedRef = ref(database, 'issues/');
      onValue(issuedRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const issuedArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setIssuedBooks(issuedArray);
          saveDataToDB('issues', issuedArray); // Save to IndexedDB
          localStorage.setItem('issuedBooks', JSON.stringify(issuedArray)); // Save to local storage
        }
      });
    } catch (error) {
      console.log('Offline: Fetching issued books from IndexedDB');
      const offlineIssuedBooks = await getDataFromDB('issues');
      setIssuedBooks(offlineIssuedBooks);
    }
  };

  useEffect(() => {
    const offlineBooks = localStorage.getItem('books');
    if (offlineBooks) {
      setBooks(JSON.parse(offlineBooks));
    } else {
      fetchBooks(); // Fetch books when app loads
    }

    const offlineIssuedBooks = localStorage.getItem('issuedBooks');
    if (offlineIssuedBooks) {
      setIssuedBooks(JSON.parse(offlineIssuedBooks));
    } else {
      fetchIssuedBooks(); // Fetch issued books when app loads
    }
  }, []);

  return (
    <Router>
      <div>
        <Navbar />
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}><svg width="24" height="24" stroke="#2d0144" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="12" r="9.5" fill="none" strokeWidth="3" strokeLinecap="round"><animate attributeName="stroke-dasharray" dur="1.5s" calcMode="spline" values="0 150;42 150;42 150;42 150" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/><animate attributeName="stroke-dashoffset" dur="1.5s" calcMode="spline" values="0;-16;-59;-59" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/></circle><animateTransform attributeName="transform" type="rotate" dur="2s" values="0 12 12;360 12 12" repeatCount="indefinite"/></g></svg></div>
}>
          <Routes>
            {/* Admin Routes */}
            <Route path="/admin" element={isAdmin ? <AdminPage issuedBooks={issuedBooks} /> : <Navigate to="/student" />} />
            <Route path="/AddBook" element={isAdmin ? <AddBook /> : <Navigate to="/student" />} />
            <Route path="/user-requests" element={isAdmin ? <UserRequestedBooksPage /> : <Navigate to="/student" />} />

            {/* Student and General Routes */}
            <Route path="/student" element={<StudentPage books={books} />} />
            <Route path="/faculty" element={<FacultyPage />} />
            <Route path="/notices" element={<NoticePage />} />
            <Route path="/scanner" element={<Scanner />} />
            <Route path="/required-books" element={<RequiredBookPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/TermsandConditions" element={<TermsandConditions />} />
            <Route path="/CancellationandRefund" element={<CancellationandRefund />} />
            <Route path="/ShippingandDelivery" element={<ShippingandDelivery />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/books" element={<BookListPage books={books} />} />
            <Route path="/issue-book" element={<IssueBook books={books} />} />
            <Route path="/mybooks" element={isAdmin ? <MyBooks issuedBooks={issuedBooks} /> : <Navigate to="/student" />} />
            <Route path="/add-notice" element={isAdmin ? <AddNotice /> : <Navigate to="/student" />} />
            <Route path="/users" element={isAdmin ? <UserList /> : <Navigate to="/student" />} />
            <Route path="/user-chat/:userEmail" element={isAdmin ? <UserChat /> : <Navigate to="/student" />} />
            <Route path="/AdminPaymentPage" element={isAdmin ? <AdminPaymentPage /> : <Navigate to="/student" />} />
            <Route path="/AddToGallery" element={isAdmin ? <AddToGallery /> : <Navigate to="/student" />} />

            {/* Default Route */}
            <Route path="/" element={<HomePage />} />
            {/* Page Not Found Route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;